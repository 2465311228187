import { MapConfiguration, MapTileLayer } from '../interfaces/map.interface';
import * as L from 'leaflet';

export const mapTile: MapTileLayer = {
  name: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png',
  attribution: 'Tiles &copy; CartoDB',
};

export const mapConfig: MapConfiguration = {
  center: [54.7512, -2.9515],
  preferCanvas: true,
  zoom: 6,
  minZoom: 5,
  maxZoom: 12,
  maxBounds: new L.LatLngBounds(
    new L.LatLng(49.5, -8.3),
    new L.LatLng(61.2, 2.5)
  ),
  maxBoundsViscosity: 0,
};

export const mapColors = [
  '#00703c',
  '#1d70b8',
  '#003078',
  '#4c2c92',
  '#912b88'
];