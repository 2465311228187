import { UserOptions } from 'jspdf-autotable';

export const DISPLAYED_COLUMNS: string[] = [
  'award_date',
  'recipient_org',
  'title',
  'amount_awarded',
  'ward',
  'local_authority',
  'region',
  'uk_constituency',
  'good_cause_area',
];

export const DEFAULT_ORDER: string = '-award_date';

export const DEFAULT_PAGE: number = 1;

export const DEFAULT_LIMIT: number = 10;

export const MIN_SEARCH_COUNT: number = 3;

export const MAX_SEARCH_COUNT: number = 140;

export const EXPORT_PDF_OPTIONS: UserOptions = {
  theme: 'striped',
  tableWidth: 'wrap',
  startY: 5,
  margin: 5,
  styles: {
    cellPadding: 0.5,
    fontSize: 8,
    cellWidth: 'wrap',
    overflow: 'linebreak',
  },
  columnStyles: {
    identifier: {
      cellWidth: 25,
    },
    award_date: {
      cellWidth: 20,
    },
    recipient_org: {
      cellWidth: 20,
    },
    title: {
      cellWidth: 40,
    },
    description: {
      cellWidth: 40,
    },
    amount_awarded: {
      cellWidth: 20,
    },
    ward: {
      cellWidth: 20,
    },
    local_authority: {
      cellWidth: 20,
    },
    region: {
      cellWidth: 20,
    },
    uk_constituency: {
      cellWidth: 20,
    },
    funding_org: {
      cellWidth: 20,
    },
    good_cause_area: {
      cellWidth: 23,
    },
  },
};

export const GRANTS_FILE_NAME: string = 'grants_statistics';

export const EXPORT_FILEDS = [
  {
    dataKey: 'identifier',
    title: 'Identifier',
  },
  {
    dataKey: 'award_date',
    title: 'Date grant awarded',
  },
  {
    dataKey: 'recipient_org',
    title: 'Recipient',
  },
  {
    dataKey: 'title',
    title: 'Title',
  },
  {
    dataKey: 'description',
    title: 'Description',
  },
  {
    dataKey: 'amount_awarded',
    title: 'Amount (GBP)',
  },
  {
    dataKey: 'ward',
    title: 'Ward',
  },
  {
    dataKey: 'local_authority',
    title: 'Local authority',
  },
  {
    dataKey: 'region',
    title: 'Region',
  },
  {
    dataKey: 'uk_constituency',
    title: 'Parliamentary constituency',
  },
  {
    dataKey: 'funding_org',
    title: 'Funding organisation',
  },
  {
    dataKey: 'good_cause_area',
    title: 'Good cause area',
  },
];

export const DEFAULT_FILTERS = {
  good_cause_area: '',
  funding_org: '',
  region: '',
  local_authority: '',
  uk_constituency: '',
  ward: '',
  recipient_org: '',
  award_date_after: '',
  award_date_before: '',
  amount_awarded_min: '',
  amount_awarded_max: '',
  search: '',
};

export const SORT_BY_NAME = [
  'recipient_org',
  'ward',
  'local_authority',
  'region',
  'uk_constituency',
  'funding_org',
  'good_cause_area',
];
