<header class="govuk-header" role="banner" data-module="govuk-header">
<ng-content></ng-content>
  <div
    class="govuk-header__container govuk-width-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div class="govuk-header__logo">
      <a
        [routerLink]="['']"  
        class="govuk-header__link govuk-header__link--homepage"
      >
        <div style="display: inline-block;" class="govuk-header__logotype">
          <span class="govuk-header__logotype--border"></span>
          <span class="govuk-header__logotype--image"></span>
          <span class="govuk-header__logotype--text">
            Department for Culture, Media & Sport
          </span>
        </div>
      </a>
    </div>
    <div
      class="govuk-header__content"
      [ngClass.xs]="'mobile'"
      [ngClass.sm]="'mobile'"
      [ngClass.md]="'mobile'"
    >
      <button
        type="button"
        class="govuk-header__menu-button govuk-js-header-toggle"
        aria-controls="navigation"
        aria-label="Show or hide navigation menu"
      >
        Menu
      </button>
      <nav>
        <ul
          id="navigation"
          class="govuk-header__navigation"
          aria-label="Navigation menu"
        >
          <li class="govuk-header__navigation-item">
            <a
              class="govuk-header__link"
              [routerLink]="['/charts']"
              [routerLinkActive]="'active'"
              >Charts</a
            >
          </li>
          <li class="govuk-header__navigation-item">
            <a
              class="govuk-header__link"
              [routerLink]="['/data']"
              [routerLinkActive]="'active'"
              >Data</a
            >
          </li>
          <li class="govuk-header__navigation-item">
            <a
              class="govuk-header__link"
              [routerLink]="['/map']"
              [routerLinkActive]="'active'"
              >Map</a
            >
          </li>
          <li class="govuk-header__navigation-item">
            <a
              class="govuk-header__link"
              [routerLink]="['/about']"
              [routerLinkActive]="'active'"
              >About</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>
