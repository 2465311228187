import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuicklinkStrategy, QuicklinkModule } from 'ngx-quicklink';
import { AuthGuard } from './auth-guard';

const appRoutes: Routes = [
  {
    path: 'about',
    loadChildren: () =>
      import('./routes/about/about.module').then((mod) => mod.AboutModule),
    data: { title: 'About this database' },
  },
  {
    path: 'charts',
    loadChildren: () =>
      import('./routes/statistic/statistic.module').then(
        (mod) => mod.StatisticModule
      ),
    data: { showFilters: true, title: 'National Lottery grants: charts' },
  },
  {
    path: 'data',
    loadChildren: () =>
      import('./routes/grants/grants.module').then((mod) => mod.GrantsModule),
    data: { showFilters: true, title: 'National Lottery grants: data' },
  },
  {
    path: 'map',
    loadChildren: () =>
      import('./routes/interactive-map/interactive-map.module').then(
        (mod) => mod.InteractiveMapModule
      ),
    data: { showFilters: true, title: 'National Lottery grants: map' },
  },
  {
    path: '404',
    loadChildren: () =>
      import('./routes/pageNotFound/pageNotFound.module').then(
        (mod) => mod.PageNotFoundModule
      ),
    data: { title: 'Page not found' },
  },
  {
    path: '500',
    loadChildren: () =>
      import('./routes/internalServerError/internalServerError.module').then(
        (mod) => mod.InternalServerErrorModule
      ),
    data: { title: 'Internal Server Error' },
  },
  {
    path: 'cookie-policy',
    loadChildren: () =>
      import('./routes/cookie-policy/cookie-policy.module').then(
        (mod) => mod.CookiePolicyModule
      ),
    data: { title: 'Cookie policy' },
  },
  {
    path: 'accessibility-statement',
    loadChildren: () =>
      import(
        './routes/accessibility-statement/accessibility-statement.module'
      ).then((mod) => mod.AccessibilityStatementModule),
    data: { title: 'Accessibility statement' },
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./routes/sign-in/sign-in.module').then(
        (mod) => mod.SignInModule
      ),
    data: { title: 'SignIn' },
  },
  {
    path: 'upload-grant',
    loadChildren: () =>
      import('./routes/upload-grant/upload-grant.module').then(
        (mod) => mod.UploadGrantModule
      ),
    data: { title: 'Upload grant' },
    canActivate: [AuthGuard],
  },
  {
    path: 'cookie-details',
    loadChildren: () =>
      import('./routes/cookie-details/cookie-details.module').then(
        (mod) => mod.CookieDetailsModule
      ),
    data: { title: 'Details about cookies' },
  },
  {
    path: 'privacy-notice',
    loadChildren: () =>
      import('./routes/privacy-policy/privacy-policy.module').then(
        (mod) => mod.PrivacyPolicyModule
      ),
    data: { title: 'Privacy notice' },
  },
  {
    path: '',
    redirectTo: 'charts',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
