import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const dateBeforeValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const dateTo = control.parent.get('awardDateTo').value;
  const dateFrom = control.parent.get('awardDateFrom').value;

  if (!dateTo || !dateFrom) {
    return null;
  }

  if (
    new Date(dateFrom.toDateString()).getTime() ===
    new Date(dateTo.toDateString()).getTime()
  ) {
    return null;
  }

  if (
    new Date(dateFrom.toDateString()).getTime() <
    new Date(dateTo.toDateString()).getTime()
  ) {
    return null;
  }

  return { dateIsNotBefore: true };
};
