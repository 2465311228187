import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  WARD,
  ENTER_CHARS_TO_START_SEARCH,
  FILTER_HINT,
  FILTER_SEARCH_SEPARATOR,
  FUNDING_ORG,
  GOOD_CAUSE_AREA,
  LOADING,
  LOCAL_AUTHORITY,
  NO_RESULTS,
  RECIPIENT_ORG,
  REGION,
  UK_CONSTITUENCY,
} from 'src/app/shared/constants/filter.constants';
// ────────────────────────────────────────────────────────────────────────────────
// Autocomplete & chips
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { EMPTY, Observable, Subject } from 'rxjs';
import { Filter } from 'src/app/shared/interfaces/filter.interface';

import {
  startWith,
  map,
  debounceTime,
  tap,
  switchMap,
  finalize,
  distinctUntilChanged,
  takeUntil,
} from 'rxjs/operators';
import { dateBeforeValidator } from 'src/app/shared/validators/date-before.validator';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
// ────────────────────────────────────────────────────────────────────────────────
// Platform detection for calendar
import { Platform } from '@angular/cdk/platform';
import { GrantParams } from 'src/app/shared/interfaces/grant.interface';
import { MessageService } from 'src/app/shared/services/message/message.service';
import { MatSelect } from '@angular/material/select';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { Helpers } from 'src/app/shared/helpers/helpers';
import { DEBOUNCE_TIME } from 'src/app/shared/constants/general.constants';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, AfterViewInit, OnDestroy {
  public filterForm: FormGroup;
  // ────────────────────────────────────────────────────────────────────────────────
  // Get static data for filters
  public goodCauseAreaData$;
  public fundingOrganizationData$;
  public regionData$;

  public localAuthorityData: Filter[];
  public filteredLocalAuthorityData: Filter[];
  public currentLocalAuthorityData: Filter[] = [];

  public parliamentaryConstituencyData: Filter[];
  public filteredParliamentaryConstituencyData: Observable<Filter[]>;
  public currentParliamentaryConstituencyData: Filter[] = [];

  public recipientOrganizationCityData: Filter[];
  public filteredRecipientOrganizationCityData: Observable<Filter[]>;
  public currentRecipientOrganizationCityData: Filter[] = [];

  public recipientOrganizationNameData: Filter[];
  public filteredRecipientOrganizationNameData: Observable<Filter[]>;
  public currentRecipientOrganizationNameData: Filter[] = [];

  public minDate: Date;
  public maxDate: Date;
  // ────────────────────────────────────────────────────────────────────────────────
  // Configure autocomplete with chips

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;

  public isLoading: boolean = false;
  public noResults: boolean = false;

  public loading: string = LOADING;
  public noResult: string = NO_RESULTS;
  public hint: string = FILTER_HINT;

  public filtersProcessing: boolean = false;
  public resetProcessing: boolean = false;

  // ────────────────────────────────────────────────────────────────────────────────
  // Input bindings

  @ViewChild('goodCauseAreaInput')
  goodCauseAreaInput: MatSelect;
  @ViewChild('fundingOrganizationInput')
  fundingOrganizationInput: MatSelect;
  @ViewChild('regionInput')
  regionInput: MatSelect;

  @ViewChild('localAuthorityInput', { read: MatAutocompleteTrigger })
  localAuthorityInput: MatAutocompleteTrigger;

  @ViewChild('localAuthorityAutocomplete')
  localAuthorityAutocomplete: MatAutocomplete;

  @ViewChild('parliamentaryConstituencyInput', { read: MatAutocompleteTrigger })
  parliamentaryConstituencyInput: MatAutocompleteTrigger;
  @ViewChild('parliamentaryConstituencyAutocomplete')
  parliamentaryConstituencyAutocomplete: MatAutocomplete;

  @ViewChild('recipientOrganizationCityInput', { read: MatAutocompleteTrigger })
  recipientOrganizationCityInput: MatAutocompleteTrigger;
  @ViewChild('recipientOrganizationCityAutocomplete')
  recipientOrganizationCityAutocomplete: MatAutocomplete;

  @ViewChild('recipientOrganizationNameInput', { read: MatAutocompleteTrigger })
  recipientOrganizationNameInput: MatAutocompleteTrigger;
  @ViewChild('recipientOrganizationNameAutocomplete')
  recipientOrganizationNameAutocomplete: MatAutocomplete;


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth <= 600;
  }
  public isMobile: boolean = false;

  private awardDateFromDefaultValue: Date = new Date();

  private _unsubscribeAll: Subject<any>;

  /**
   * @param  {FormBuilder} privateformBuilder
   * @param  {FiltersService} privatefiltersService
   * @param  {MessageService} privatemessageService
   * @param  {Platform} private_platform
   * @param  {StorageService} privatestorageService
   */
  constructor(
    private formBuilder: FormBuilder,
    private filtersService: FiltersService,
    private messageService: MessageService,
    private _platform: Platform,
    private storageService: StorageService
  ) {
    this._unsubscribeAll = new Subject();

    this.storageService.clearFilterData();
    this.isMobile = window.innerWidth <= 600;

    this.awardDateFromDefaultValue.setDate(
      this.awardDateFromDefaultValue.getDate() - 1
    );

    this.initFilterForm();

    this.goodCauseAreaData$ =
      this.filtersService.getFilterData(GOOD_CAUSE_AREA);

    this.fundingOrganizationData$ =
      this.filtersService.getFilterData(FUNDING_ORG);
    this.regionData$ = this.filtersService.getFilterData(REGION);

    this.remoteSearchInit(
      LOCAL_AUTHORITY,
      'localAuthority',
      'filteredLocalAuthorityData',
      'currentLocalAuthorityData'
    );

    this.remoteSearchInit(
      UK_CONSTITUENCY,
      'parliamentaryConstituency',
      'filteredParliamentaryConstituencyData',
      'currentParliamentaryConstituencyData'
    );

    this.remoteSearchInit(
      WARD,
      'recipientOrganizationCity',
      'filteredRecipientOrganizationCityData',
      'currentRecipientOrganizationCityData'
    );

    this.remoteSearchInit(
      RECIPIENT_ORG,
      'recipientOrganizationName',
      'filteredRecipientOrganizationNameData',
      'currentRecipientOrganizationNameData'
    );

    this.messageService
      .getMessage()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ text, data }: { text: string; data: any }) => {
        if (text === 'filtersClosed') {
          this.removeOverlays();
        }
      });
  }

  ngOnInit(): void {
    this.filtersService
      .getDatesRange()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((range: { max_date: string; min_date: string }) => {
        this.maxDate = new Date(range.max_date);
        this.minDate = new Date(range.min_date);
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * @param  {number} item
   * @param  {string} current
   * @param  {string} origin
   * @returns void
   */
  public remove(
    item: number,
    current: string,
    origin: string,
    inputName?: string
  ): void {
    let optionToAdd: Filter;
    const index = this[current].findIndex((currentOption: Filter) => {
      if (currentOption.id === item) {
        optionToAdd = {
          id: currentOption.id,
          name: currentOption.name,
        };
      }
      return currentOption.id === item;
    });

    if (index >= 0) {
      this[current].splice(index, 1);
      if (origin !== '') this[origin].push(optionToAdd);
    }

    this.sortByProp(origin, 'name');

    if (!!inputName) {
      this.filterForm.get(inputName).setValue(' ');
      this.filterForm.get(inputName).setValue('');
    }
  }

  /**
   * @param  {MatAutocompleteSelectedEvent} event
   * @param  {string} current
   * @param  {string} origin
   * @param  {string} inputName
   * @returns void
   */
  public selected(
    event: MatAutocompleteSelectedEvent,
    current: string,
    origin: string,
    inputName: string
  ): void {
    this[current].push({
      id: event.option.value.id,
      name: event.option.viewValue,
    });
    if (origin !== '') {
      const index = this[origin].findIndex(
        (currentOption: Filter) => currentOption.id === event.option.value.id
      );
      this[origin].splice(index, 1);
    }

    if (!!inputName && this[`${inputName}Input`] !== undefined) {
      this[`${inputName}Input`]._element.nativeElement.blur();
    }

    this.clearInputValue(inputName);
  }
  /**
   * @param  {FormGroup} formValue
   * @param  {boolean} isValid
   * @param  {Event} $event
   * @returns void
   */
  public processFilterForm(
    formValue: any,
    isValid: boolean,
    $event: Event
  ): void {
    if (this.isMobile) {
      this.messageService.sendMessage('closeFilter');
    }
    $event.preventDefault();

    if (!isValid || this.filterForm.pristine === true) {
      return;
    }

    this.filtersProcessing = true;

    const filterData: Partial<GrantParams> = {
      good_cause_area: Array.isArray(formValue.goodCauseArea)
        ? formValue.goodCauseArea.join(FILTER_SEARCH_SEPARATOR)
        : '',
      funding_org: Array.isArray(formValue.fundingOrganization)
        ? formValue.fundingOrganization.join(FILTER_SEARCH_SEPARATOR)
        : '',
      region: Array.isArray(formValue.region)
        ? formValue.region.join(FILTER_SEARCH_SEPARATOR)
        : '',
      local_authority: this.formatSelectedValues('currentLocalAuthorityData'),
      uk_constituency: this.formatSelectedValues(
        'currentParliamentaryConstituencyData'
      ),
      ward: this.formatSelectedValues('currentRecipientOrganizationCityData'),
      recipient_org: this.formatSelectedValues(
        'currentRecipientOrganizationNameData'
      ),
      award_date_after: Helpers.formatDate(formValue.awardDateFrom),
      award_date_before: Helpers.formatDate(formValue.awardDateTo),
      amount_awarded_max: formValue.amountAwardedTo,
      amount_awarded_min: formValue.amountAwardedFrom,
    };
    this.storageService.saveFilterData(filterData);
    this.messageService.sendMessage('filtersSubmitted', filterData);
    this.filtersProcessing = false;
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Will reset existing models and form
  public resetForm() {
    if (this.isMobile) {
      this.messageService.sendMessage('closeFilter');
    }
    if (this.filterForm.pristine === true) {
      return;
    }
    this.resetProcessing = true;
    const resetInputs = {
      goodCauseArea: '',
      fundingOrganization: '',
      region: '',
      localAuthority: '',
      parliamentaryConstituency: '',
      recipientOrganizationCity: '',
      awardDateFrom: '',
      awardDateTo: '',
      amountAwardedFrom: '',
      amountAwardedTo: '',
      recipientOrganizationName: '',
    };

    this.currentLocalAuthorityData = [];
    this.currentParliamentaryConstituencyData = [];
    this.currentRecipientOrganizationCityData = [];
    this.currentRecipientOrganizationNameData = [];

    this.filterForm.markAsPristine();
    this.filterForm.markAsUntouched();
    this.filterForm.updateValueAndValidity();

    this.filterForm.reset(resetInputs);

    for (const key in resetInputs) {
      if (Object.prototype.hasOwnProperty.call(resetInputs, key)) {
        this.clearInputValue(key);
      }
    }

    this.messageService.sendMessage('filtersReset');
    this.resetProcessing = false;
  }

  public dateChanged() {
    this.filterForm.controls['awardDateFrom'].updateValueAndValidity();
    this.filterForm.controls['awardDateTo'].updateValueAndValidity();
  }

  public selectionChanged(formControl: string) {
    this[formControl].close();
  }
  public focus(input: any) {
    input.focus();
  }

  private sortByProp(origin: string, sortPropName: string) {
    if (this[origin] === undefined || !Array.isArray(this[origin])) {
      return;
    }
    this[origin] = Helpers.sortByProp(this[origin], sortPropName);
  }

  private initFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      goodCauseArea: [''],
      fundingOrganization: [''],
      region: [''],
      localAuthority: [''],
      parliamentaryConstituency: [''],
      recipientOrganizationCity: [''],
      awardDateFrom: ['', [dateBeforeValidator]],
      awardDateTo: ['', [dateBeforeValidator]],
      amountAwardedFrom: ['', Validators.min(0)],
      amountAwardedTo: ['', Validators.min(0)],
      recipientOrganizationName: [''],
    });
  }

  /**
   * @param  {string} entity
   * @param  {string} inputName
   * @param  {string} filteredData
   * @param  {string} currentData
   * @returns void
   */
  private remoteSearchInit(
    entity: string,
    inputName: string,
    filteredData: string,
    currentData: string
  ): void {
    this.filterForm
      .get(inputName)
      .valueChanges.pipe(
        debounceTime(DEBOUNCE_TIME),
        distinctUntilChanged(),
        tap((search: string) => {
          this[filteredData] = [];
          this.noResults = false;
          this.isLoading = search !== '' && search.length > 2;
        }),
        switchMap((search: string) => {
          if (
            typeof search === 'object' ||
            search.length <= ENTER_CHARS_TO_START_SEARCH
          ) {
            return EMPTY;
          }
          return this.filtersService.getFilterData(entity, search).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          );
        })
      )
      .subscribe((data: Filter[]) => {
        if (Array.isArray(data) && data.length === 0) {
          this.noResults = true;
        }
        this[filteredData] = this.filtersSearchResults(data, this[currentData]);
        this.isLoading = false;
      });
  }

  /**
   * @param  {Filter[]} selectedValues
   * @param  {Filter[]} originData
   * @returns Filter
   */
  private filtersSearchResults(
    selectedValues: Filter[],
    originData: Filter[]
  ): Filter[] {
    if (Array.isArray(originData) && originData.length === 0) {
      return selectedValues;
    }

    const filretedNames = originData.map((item: Filter) => {
      return item.name;
    });

    const namesFromServer = selectedValues.filter(
      (item: Filter) => !filretedNames.includes(item.name)
    );

    return namesFromServer;
  }

  /**
   * @param  {string} entity
   * @returns string
   */
  private formatSelectedValues(entity: string): string {
    const values: Filter[] = this[entity];
    let formattedValues: string[] = [];
    if (!Array.isArray(values) || values.length === 0) {
      return '';
    }

    for (let index = 0; index < values.length; index++) {
      const element: Filter = values[index];
      formattedValues.push(element.name);
    }
    return formattedValues.join(FILTER_SEARCH_SEPARATOR);
  }

  private removeOverlays() {
    this.goodCauseAreaInput.close();
    this.fundingOrganizationInput.close();
    this.regionInput.close();
    this.localAuthorityInput.closePanel();
    this.parliamentaryConstituencyInput.closePanel();
    this.recipientOrganizationCityInput.closePanel();
    this.recipientOrganizationNameInput.closePanel();
  }
  /**
   * @param  {string} inputName
   */
  private clearInputValue(inputName: string) {
    if (this[`${inputName}Input`] === undefined) {
      return;
    }

    if (this[`${inputName}Input`].nativeElement) {
      this[`${inputName}Input`].nativeElement.value = '';
    }

    if (this[`${inputName}Input`]._element) {
      this[`${inputName}Input`]._element.nativeElement.value = '';
    }

    this.filterForm.get(inputName).setValue('');
  }
}
