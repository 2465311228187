import { Inject, Injectable } from '@angular/core';
import {
  SESSION_STORAGE,
  StorageService as NgxStorageService,
} from 'ngx-webstorage-service';
import { GrantParams } from '../../interfaces/grant.interface';

const FILTERS_KEY = 'dcms-filters';
const ACCESS_TOKEN_KEY = 'dcms-accessToken';
const EXPIRATION_TIME_KEY = 'dcms-expirationTime';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /**
   * @param  {} @Inject(SESSION_STORAGE
   * @param  {NgxStorageService} privatestorage
   */
  constructor(@Inject(SESSION_STORAGE) private storage: NgxStorageService) {}
  /**
   * @param  {Partial<GrantParams>} data
   */
  public saveFilterData(data: Partial<GrantParams>) {
    this.storage.set(FILTERS_KEY, data);
  }

  public getFilterData(): Partial<GrantParams> {
    return this.storage.get(FILTERS_KEY);
  }

  public clearFilterData(): void {
    this.storage.remove(FILTERS_KEY);
  }

  public saveAccessTokenData(token: string): void {
    this.storage.set(ACCESS_TOKEN_KEY, token);
  }

  public getAccessTokenData(): string {
    return this.storage.get(ACCESS_TOKEN_KEY);
  }

  public clearAccessTokenData(): void {
    this.storage.remove(ACCESS_TOKEN_KEY);
  }

  public saveExpirationTimeData(timestamp: string): void {
    this.storage.set(EXPIRATION_TIME_KEY, timestamp);
  }

  public getExpirationTimeData(): string {
    return this.storage.get(EXPIRATION_TIME_KEY);
  }

  public clearExpirationTimeData(): void {
    this.storage.remove(EXPIRATION_TIME_KEY);
  }
}
