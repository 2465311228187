import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { API_BASE } from '../../constants/general.constants';

const API_LINK: string = 'filters/';
const API_GRANTS_URL: string = 'grants/';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  /**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http: HttpClient) {}
  /**
   * @param  {string} entity
   * @param  {string=''} search
   * @param  {'id'|'-id'|'name'|'-name'='name'} order
   * @param  {'json'='json'} format
   * @returns Observable
   */
  getFilterData(
    entity: string,
    search: string = '',
    order: 'id' | '-id' | 'name' | '-name' = 'name',
    format: 'json' = 'json'
  ): Observable<Object> {
    let params = new HttpParams()
      .set('format', format.toString())
      .set('ordering', order.toString())
      .set('search', search.toString());

    return this.http
      .get(`${API_BASE}${API_LINK}${entity}/`, { params })
      .pipe(share());
  }

  getDatesRange(): Observable<Object> {
    return this.http.get(`${API_BASE}${API_GRANTS_URL}earliest_and_latest/`);
  }
}
