import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { share } from 'rxjs/operators';
import { API_BASE } from '../../constants/general.constants';
import { map } from 'rxjs/operators';
import { MessageService } from '../message/message.service';
import { GRANTS_FILE_NAME } from '../../constants/grants.constants';
import { CustomURLEncoder } from '../../http/params.encoder';
import { Helpers } from '../../helpers/helpers';
import { Grant, GrantsResponse } from '../../interfaces/grant.interface';
import { CurrencyPipe } from '@angular/common';

const API_LINK: string = 'grants/';

const EXPORT_CSV: string = 'csv-export/';

@Injectable({
  providedIn: 'root',
})
export class GrantsService {
  /**
   * @param  {HttpClient} privatehttp
   * @param  {MessageService} privatemessageService
   */
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
  ) {}
  /**
   * @param  {number=1} page
   * @param  {number=10} limit
   * @param  {string='-award_date'} ordering
   * @param  {string=''} good_cause_area
   * @param  {string=''} funding_org
   * @param  {string=''} region
   * @param  {string=''} local_authority
   * @param  {string=''} uk_constituency
   * @param  {string=''} ward
   * @param  {string=''} recipient_org
   * @param  {string=''} award_date_after
   * @param  {string=''} award_date_before
   * @param  {string=''} amount_awarded_min
   * @param  {string=''} amount_awarded_max
   */
  getGrants(
    page: number = 1,
    limit: number = 10,
    ordering: string = '-award_date',
    good_cause_area: string = '',
    funding_org: string = '',
    region: string = '',
    local_authority: string = '',
    uk_constituency: string = '',
    ward: string = '',
    recipient_org: string = '',
    award_date_after: string = '',
    award_date_before: string = '',
    amount_awarded_min: string = '',
    amount_awarded_max: string = '',
    search: string = ''
  ) {
    let params = new HttpParams({ encoder: new CustomURLEncoder() })
      .set('limit', limit.toString())
      .set('page', page.toString())
      .set('ordering', ordering.toString())

      .set('good_cause_area', good_cause_area.toString())
      .set('funding_org', funding_org.toString())
      .set('region', region.toString())
      .set('local_authority', local_authority.toString())
      .set('uk_constituency', uk_constituency.toString())
      .set('ward', ward.toString())
      .set('recipient_org', recipient_org.toString())

      .set('award_date_after', award_date_after.toString())
      .set('award_date_before', award_date_before.toString())
      .set('amount_awarded_min', amount_awarded_min.toString())
      .set('amount_awarded_max', amount_awarded_max.toString())

      .set('search', search.toString());

    return this.http
      .get(`${API_BASE}${API_LINK}`, { params })
      .pipe(
        map((res: GrantsResponse) => {
          if (res === null) {
            return;
          }

          this.messageService.sendMessage('grantsArrived', res);

          return res.results;
        })
      )
      .pipe(share());
  }

  downloadCsv(
    page: number = 1,
    limit: number = 10,
    ordering: string = '-award_date',
    good_cause_area: string = '',
    funding_org: string = '',
    region: string = '',
    local_authority: string = '',
    uk_constituency: string = '',
    ward: string = '',
    recipient_org: string = '',
    award_date_after: string = '',
    award_date_before: string = '',
    amount_awarded_min: string = '',
    amount_awarded_max: string = '',
    search: string = ''
  ) {
    const data = {
      page,
      limit,
      ordering,
      good_cause_area,
      funding_org,
      region,
      local_authority,
      uk_constituency,
      ward,
      recipient_org,
      award_date_after,
      award_date_before,
      amount_awarded_min,
      amount_awarded_max,
      search,
    };
    const querystring = Helpers.encodeQueryData(data);

    const base: string = `${API_BASE}${API_LINK}${EXPORT_CSV}?`;

    this.startDownload(`${base}${querystring}`);
  }

  private startDownload(url: string) {
    const name = `${GRANTS_FILE_NAME}_${Helpers.formatDate(
      new Date().toDateString()
    )}.csv`;
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
