import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private subject = new Subject<any>();
  /**
   * @param  {string} message
   * @param  {any} data?
   * @returns void
   */
  sendMessage(message: string, data?: any): void {
    this.subject.next({ text: message, data });
  }
  /**
   * @returns void
   */
  clearMessages(): void {
    this.subject.next();
  }
  /**
   * @returns Observable
   */
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
