import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { filter, share } from 'rxjs/operators';

import { API_BASE } from '../../constants/general.constants';
import { map } from 'rxjs/operators';
import { MessageService } from '../message/message.service';

import { CustomURLEncoder } from '../../http/params.encoder';
import { forkJoin } from 'rxjs';

const API_LINK: string = 'charts/';

const STATISTICS_DATA_BY_ROOT_CAUSE: string = 'grants-by-good-cause/';
const STATISTICS_DATA_BY_REGION: string = 'grants-by-region/';

const TIME_SERIES: string = 'time-series/';

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  /**
   * @param  {HttpClient} privatehttp
   * @param  {MessageService} privatemessageService
   */
  constructor(private http: HttpClient) {}
  /**
   * @param  {string=''} good_cause_area
   * @param  {string=''} funding_org
   * @param  {string=''} region
   * @param  {string=''} local_authority
   * @param  {string=''} uk_constituency
   * @param  {string=''} ward
   * @param  {string=''} recipient_org
   * @param  {string=''} award_date_after
   * @param  {string=''} award_date_before
   * @param  {string=''} amount_awarded_min
   * @param  {string=''} amount_awarded_max
   */
  getStatisticsData(
    good_cause_area: string = '',
    funding_org: string = '',
    region: string = '',
    local_authority: string = '',
    uk_constituency: string = '',
    ward: string = '',
    recipient_org: string = '',
    award_date_after: string = '',
    award_date_before: string = '',
    amount_awarded_min: string = '',
    amount_awarded_max: string = ''
  ) {
    let params = new HttpParams({ encoder: new CustomURLEncoder() })
      .set('good_cause_area', good_cause_area.toString())
      .set('funding_org', funding_org.toString())
      .set('region', region.toString())
      .set('local_authority', local_authority.toString())
      .set('uk_constituency', uk_constituency.toString())
      .set('ward', ward.toString())
      .set('recipient_org', recipient_org.toString())

      .set('award_date_after', award_date_after.toString())
      .set('award_date_before', award_date_before.toString())
      .set('amount_awarded_min', amount_awarded_min.toString())
      .set('amount_awarded_max', amount_awarded_max.toString());

    return forkJoin([
      this.http.get(`${API_BASE}${API_LINK}${STATISTICS_DATA_BY_ROOT_CAUSE}`, { params }),
      this.http.get(`${API_BASE}${API_LINK}${STATISTICS_DATA_BY_REGION}`, { params }),
    ]).pipe(share());
  }

  getTimeSeriesData(
    good_cause_area: string = '',
    funding_org: string = '',
    region: string = '',
    local_authority: string = '',
    uk_constituency: string = '',
    ward: string = '',
    recipient_org: string = '',
    award_date_after: string = '',
    award_date_before: string = '',
    amount_awarded_min: string = '',
    amount_awarded_max: string = ''
  ) {
    let params = new HttpParams({ encoder: new CustomURLEncoder() })
      .set('good_cause_area', good_cause_area.toString())
      .set('funding_org', funding_org.toString())
      .set('region', region.toString())
      .set('local_authority', local_authority.toString())
      .set('uk_constituency', uk_constituency.toString())
      .set('ward', ward.toString())
      .set('recipient_org', recipient_org.toString())

      .set('award_date_after', award_date_after.toString())
      .set('award_date_before', award_date_before.toString())
      .set('amount_awarded_min', amount_awarded_min.toString())
      .set('amount_awarded_max', amount_awarded_max.toString());

    return this.http
      .get(`${API_BASE}${API_LINK}${TIME_SERIES}`, { params })
      .pipe(share());
  }
}
