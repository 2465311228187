import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

export const API_BASE: string = 'api/v1/';

export const DEFAULT_ORDER = 'id';

export const ERROR_MESSAGE_TIME: number = 5e3;

export const DEBOUNCE_TIME: number = 5e2;

export const ERROR_MESSAGE_HORIZONTAL_POSITION: MatSnackBarHorizontalPosition =
  'right';

export const ERROR_MESSAGE_VERTICAL_POSITION: MatSnackBarVerticalPosition =
  'bottom';

export const COOKIE_CONSISTENT_NAME: string = 'cookieconsent_status';

export const COOKIE_ALLOW: string = 'allow';
export const COOKIE_DENY: string = 'deny';
export const COOKIE_DISSMISS: string = 'dismiss';

export const COOKIE_POLICY_ON: string = 'on';
export const COOKIE_POLICY_OFF: string = 'off';

export const GOOGLE_ANALYTICS_ID: string = '3CXNX36GSS';
