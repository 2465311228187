import { mapColors } from '../constants/map.constants';
import { Gtag, GtagConfig } from '../interfaces/gtag.interface';

export class Helpers {
  /**
   * @param  {string=''} date
   * @returns string
   */
  static formatDate(date: string = ''): string {
    if (date === '') {
      return date;
    }
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  /**
   * @param  {any[]} array
   * @param  {string} prop
   */
  static sortByProp(array: any[], prop: string) {
    return array.sort((a, b) => {
      if (a[prop] < b[prop]) {
        return -1;
      }
      if (a[prop] > b[prop]) {
        return 1;
      }
      return 0;
    });
  }
  /**
   * @param  {any} data
   */
  static encodeQueryData(data: any) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
  }

  static initGtag(config: GtagConfig): Gtag {
    if ('gtag' in window) {
      return window['gtag'];
    }

    const script = document.createElement('script');

    script.src =
      'https://www.googletagmanager.com/gtag/js?id=' + config.targetId;
    script.type = 'text/javascript';
    script.async = true;

    document.head.appendChild(script);

    (window as any).dataLayer = (window as any).dataLayer || [];

    function gtag(...args) {
      (window as any).dataLayer.push(arguments);
    }

    gtag('js', new Date());

    gtag('config', config.targetId, {
      send_page_view: false,
      ...config.configInfo,
    });

    'setParams' in config && gtag('set', config.setParams);

    'moreIds' in config && config.moreIds.forEach((id) => gtag('config', id));

    return ((window as any).gtag = gtag);
  }

  static formatAmount(n: number, isShorted = true): string {
    if (isShorted) {
      return this.formatWithLetter(this.getRoundedNumber(n, 4));
    }
    return this.formatWithWord(this.getRoundedNumber(n, 4));
  }

  static formatAmountForChart(n: number): string {
    return this.formatForChart(this.getRoundedNumber(n, 4));
  }

  static setRanges(groupCount, maxValue: number, minValue: number): number[] {
    let ranges: number[] = [];
    const delta = Math.round((maxValue - minValue) / groupCount);

    let range1: number = minValue;

    for (let i = 0; i < groupCount; i += 1) {
      const range2: number = range1 + delta;
      ranges.push(range1);
      range1 = range2;
    }
    return ranges;
  }

  static setAttributes(el: any, attrs: { [key: string]: string }): void {
    for (let item in attrs) {
      el.setAttribute(item, attrs[item]);
    }
  }

  private static getRoundedNumber(number: number, maxDigits: number): number {
    const numberLength = Number(number.toString().length);
    if (numberLength > maxDigits) {
      const zeroCount = numberLength - maxDigits;
      return (
        Number((number / Math.pow(10, zeroCount)).toFixed(0)) *
        Math.pow(10, zeroCount)
      );
    }
    return number;
  }

  static setDynamicRanges(
    groupCount: number,
    maxValue: number,
    minValue: number
  ): number[] {
    let ranges: number[] = [];
    let delta = Math.round((maxValue - minValue) / groupCount);
    while (delta < 100 && groupCount > 1) {
      delta = Math.round((maxValue - minValue) / --groupCount);
    }
    let range1: number = minValue;

    for (let i = 0; i < groupCount; i += 1) {
      const range2: number = range1 + delta;
      ranges.push(range1);
      range1 = range2;
    }
    return ranges;
  }

  private static formatWithLetter(n: number): string {
    if (n < 1e3) return n.toString();
    if (n >= 1e3 && n < 1e6) return +(n / 1e3) + 'k';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6) + 'm';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9) + 'bn';
    if (n >= 1e12) return +(n / 1e12) + 't';
  }

  private static formatWithWord(n: number): string {
    if (n < 1e3) return n.toString();
    if (n >= 1e3 && n < 1e6) return +(n / 1e3) + ' thousand';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6) + '  million';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9) + ' billion';
    if (n >= 1e12) return +(n / 1e12) + ' trillion';
  }

  private static formatForChart(n: number): string {
    if (n < 1e9) return n.toString();
    if (n >= 1e9 && n < 1e12) return +(n / 1e9) + ' billion';
    if (n >= 1e12) return +(n / 1e12) + ' trillion';
  }

  static getColor(currentItem, groupRange: number[]): string {
    const groupNumber = this.getGroup(currentItem, groupRange);
    return mapColors[groupNumber];
  }

  static getGroup(currentItem, groupRange: number[]): number {
    for (let i = 0; i < groupRange.length; i++) {
      if (currentItem.total_amount_awarded < groupRange[i + 1]) return i;
    }

    return groupRange.length - 1;
  }
}
