<div class="filters-wrapper">
  <form
    autocomplete="off"
    name="userForm"
    [formGroup]="filterForm"
    novalidate
    (ngSubmit)="processFilterForm(filterForm.value, filterForm.valid, $event)"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="space-between center"
      fxLayoutGap="5px"
    >
      <div class="govuk-form-group govuk-!-width-full govuk-!-margin-bottom-3">
        <label class="govuk-label" id="good-cause-area"> Good cause </label>

        <mat-form-field appearance="outline" class="govuk-!-width-full">
          <mat-select
            id="good-cause"
            name="good-cause-name"
            #goodCauseAreaInput
            data-test="good-cause-area"
            [formControlName]="'goodCauseArea'"
            placeholder="Select area"
            aria-label="Select good cause"
            panelClass="good-cause-panel"
            multiple
            aria-labelledby="good-cause-area"
            disableOptionCentering="true"
            (selectionChange)="selectionChanged('goodCauseAreaInput')"
          >
            <mat-option
              *ngFor="let item of goodCauseAreaData$ | async"
              [value]="item.name"
              >{{ item.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="govuk-form-group govuk-!-width-full govuk-!-margin-bottom-3">
        <label class="govuk-label" id="funding-organization-name">
          Funding organisation
        </label>
        <mat-form-field
          appearance="outline"
          class="govuk-!-width-full govuk-form-group"
        >
          <mat-select
            #fundingOrganizationInput
            id="funding-organization"
            name="funding-organization-name"
            data-test="funding-organization"
            [formControlName]="'fundingOrganization'"
            placeholder="Select names"
            aria-label="Select Funding Organization Name"
            panelClass="good-cause-panel"
            multiple
            aria-labelledby="funding-organization-name"
            disableOptionCentering="true"
            (selectionChange)="selectionChanged('fundingOrganizationInput')"
          >
            <mat-option
              *ngFor="let item of fundingOrganizationData$ | async"
              [value]="item.name"
              >{{ item.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="govuk-form-group govuk-!-width-full govuk-!-margin-bottom-3">
        <label class="govuk-label" id="region"> Region </label>

        <mat-form-field
          appearance="outline"
          class="govuk-!-width-full govuk-form-group"
        >
          <mat-select
            #regionInput
            data-test="region"
            id="region-id"
            name="region-name"
            [formControlName]="'region'"
            placeholder="Select regions"
            aria-label="Select Region"
            panelClass="good-cause-panel"
            multiple
            aria-labelledby="region"
            disableOptionCentering="true"
            (selectionChange)="selectionChanged('regionInput')"
          >
            <mat-option
              *ngFor="let item of regionData$ | async"
              [value]="item.name"
              >{{ item.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="govuk-form-group govuk-!-width-full govuk-!-margin-bottom-3">
        <label class="govuk-label" for="localAuthority">
          Local authority
        </label>
        <mat-form-field
          (click)="focus(localAuthorityInput)"
          appearance="outline"
          class="govuk-!-width-full govuk-form-group has-arrow"
        >
          <mat-chip-list
            #localAuthorityList
            aria-label="Select Local Authority"
            id="local-authority-chips-list"
          >
            <mat-chip
              *ngFor="let item of currentLocalAuthorityData"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(item.id, 'currentLocalAuthorityData', '')"
            >
              {{ item.name }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              id="localAuthority"
              data-test="localAuthority"
              #localAuthorityInput
              [formControlName]="'localAuthority'"
              name="localAuthority"
              [matAutocomplete]="localAuthorityAutocomplete"
              [matChipInputFor]="localAuthorityList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              placeholder="Start typing authority"
            />
          </mat-chip-list>

          <mat-autocomplete
            #localAuthorityAutocomplete="matAutocomplete"
            (optionSelected)="
              selected(
                $event,
                'currentLocalAuthorityData',
                '',
                'localAuthority'
              )
            "
          >
            <mat-option *ngIf="isLoading">{{ loading }}</mat-option>
            <mat-option *ngIf="noResults">{{ noResult }}</mat-option>
            <mat-option
              *ngFor="let item of filteredLocalAuthorityData"
              [value]="item"
            >
              {{ item.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-lg="column"
          fxLayoutAlign.lt-lg="center start"
        >
          <div class="govuk-hint">
            {{ hint }}
          </div>
          <a
            class="govuk-link"
            href="https://www.gov.uk/find-local-council"
            target="_blank"
            rel="noopener noreferrer"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            ><span>Local authorities</span> <mat-icon>open_in_new</mat-icon></a
          >
        </div>
      </div>

      <div class="govuk-form-group govuk-!-width-full govuk-!-margin-bottom-3">
        <label class="govuk-label" for="recipientOrganizationCity">
          Ward
        </label>
        <mat-form-field
          appearance="outline"
          (click)="focus(recipientOrganizationCityInput)"
          class="govuk-!-width-full govuk-form-group has-arrow"
        >
          <mat-chip-list
            #recipientOrganizationCityList
            aria-label="Select Recipient Organization City"
            id="recipient-organization-city-chips-list"
          >
            <mat-chip
              *ngFor="let item of currentRecipientOrganizationCityData"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="
                remove(item.id, 'currentRecipientOrganizationCityData', '')
              "
            >
              {{ item.name }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              id="recipientOrganizationCity"
              data-test="recipientOrganizationCity"
              #recipientOrganizationCityInput
              name="recipientOrganizationCity"
              [formControlName]="'recipientOrganizationCity'"
              [matAutocomplete]="recipientOrganizationCityAutocomplete"
              [matChipInputFor]="recipientOrganizationCityList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              placeholder="Start typing ward"
            />
          </mat-chip-list>
          <mat-autocomplete
            #recipientOrganizationCityAutocomplete="matAutocomplete"
            (optionSelected)="
              selected(
                $event,
                'currentRecipientOrganizationCityData',
                '',
                'recipientOrganizationCity'
              )
            "
          >
            <mat-option *ngIf="isLoading">{{ loading }}</mat-option>
            <mat-option *ngIf="noResults">{{ noResult }}</mat-option>
            <mat-option
              *ngFor="let item of filteredRecipientOrganizationCityData"
              [value]="item"
            >
              {{ item.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-lg="column"
          fxLayoutAlign.lt-lg="center start"
        >
          <div class="govuk-hint">
            {{ hint }}
          </div>
          <a
            class="govuk-link"
            href="https://www.gov.uk/find-your-local-councillors"
            target="_blank"
            rel="noopener noreferrer"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            ><span>Wards</span> <mat-icon>open_in_new</mat-icon></a
          >
        </div>
      </div>

      <div class="govuk-form-group govuk-!-width-full govuk-!-margin-bottom-3">
        <label class="govuk-label" for="parliamentaryConstituency">
          Parliamentary constituency
        </label>
        <mat-form-field
          appearance="outline"
          (click)="focus(parliamentaryConstituencyInput)"
          class="govuk-!-width-full govuk-form-group has-arrow"
        >
          <mat-chip-list
            id="parliamentary-constituency-chips-list"
            #parliamentaryConstituencyList
            aria-label="Select Parliamentary Constituency"
          >
            <mat-chip
              *ngFor="let item of currentParliamentaryConstituencyData"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="
                remove(item.id, 'currentParliamentaryConstituencyData', '')
              "
            >
              {{ item.name }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              id="parliamentaryConstituency"
              data-test="parliamentaryConstituency"
              #parliamentaryConstituencyInput
              [formControlName]="'parliamentaryConstituency'"
              name="parliamentaryConstituency"
              [matAutocomplete]="parliamentaryConstituencyAutocomplete"
              [matChipInputFor]="parliamentaryConstituencyList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              placeholder="Start typing constituency"
            />
          </mat-chip-list>
          <mat-autocomplete
            #parliamentaryConstituencyAutocomplete="matAutocomplete"
            (optionSelected)="
              selected(
                $event,
                'currentParliamentaryConstituencyData',
                '',
                'parliamentaryConstituency'
              )
            "
          >
            <mat-option *ngIf="isLoading">{{ loading }}</mat-option>
            <mat-option *ngIf="noResults">{{ noResult }}</mat-option>
            <mat-option
              *ngFor="let item of filteredParliamentaryConstituencyData"
              [value]="item"
            >
              {{ item.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-lg="column"
          fxLayoutAlign.lt-lg="center start"
        >
          <div class="govuk-hint">
            {{ hint }}
          </div>
          <a
            class="govuk-link"
            href="https://members.parliament.uk/constituencies/"
            target="_blank"
            rel="noopener noreferrer"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            ><span>Parliamentary constituencies</span>
            <mat-icon>open_in_new</mat-icon></a
          >
        </div>
      </div>

      <div class="govuk-form-group govuk-!-width-full govuk-!-margin-bottom-3">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between"
          fxLayoutGap="11px"
          fxLayout.lt-lg="column"
        >
          <div fxLayout="column">
            <label for="data-grant-awarded-from" class="govuk-label"
              >Date grant awarded from</label
            >

            <mat-form-field appearance="outline" class="govuk-!-width-full">
              <input
                id="data-grant-awarded-from"
                matInput
                type="text"
                [formControlName]="'awardDateFrom'"
                [matDatepicker]="awardDateFromPicker"
                [min]="minDate"
                [max]="maxDate"
                name="awardDateFrom"
                (focus)="awardDateFromPicker.open()"
                tabindex="-1"
                readonly
                placeholder="Select date from"
                (dateChange)="dateChanged()"
              />
              <mat-datepicker-toggle
                matSuffix
                [aria-label]="'open calendar to select date grant awarded from'"
                [for]="awardDateFromPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                [touchUi]="isMobile"
                #awardDateFromPicker
              ></mat-datepicker>
            </mat-form-field>
          </div>

          <div fxLayout="column">
            <label for="data-grant-awarded-to" class="govuk-label"
              >Date grant awarded to</label
            >

            <mat-form-field appearance="outline" class="govuk-!-width-full">
              <input
                matInput
                type="text"
                id="data-grant-awarded-to"
                [formControlName]="'awardDateTo'"
                name="awardDateTo"
                [min]="minDate"
                [max]="maxDate"
                readonly
                [matDatepicker]="awardDateToPicker"
                (focus)="awardDateToPicker.open()"
                tabindex="-1"
                placeholder="Select date to"
                (dateChange)="dateChanged()"
              />
              <mat-datepicker-toggle
                matSuffix
                [aria-label]="'open calendar to select date grant awarded to'"
                [for]="awardDateToPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                [touchUi]="isMobile"
                #awardDateToPicker
              ></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <span
        style="max-width: fit-content"
        *ngIf="
          filterForm.get('awardDateTo').hasError('dateIsNotBefore') ||
          filterForm.get('awardDateFrom').hasError('dateIsNotBefore')
        "
        class="govuk-error-message"
      >
        <span class="govuk-visually-hidden">Error:</span> Award date from should
        be earlier than award date to
      </span>
      <div class="govuk-form-group govuk-!-width-full  govuk-!-margin-bottom-3">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between"
          fxLayoutGap="11px"
          fxLayout.lt-lg="column"
        >
          <div fxFlex="50%" fxLayout="column">
            <label for="amountAwardedFrom" class="govuk-label"
              >Amount from</label
            >
            <mat-form-field appearance="outline" class="govuk-!-width-full">
              <input
                type="text"
                [mask]="'separator.2'"
                matInput
                name="amountAwardedFrom"
                [formControlName]="'amountAwardedFrom'"
                placeholder="Enter amount from"
                id="amountAwardedFrom"
                data-test="amountAwardedFrom"
              />
              <mat-icon matSuffix>£</mat-icon>
            </mat-form-field>
          </div>

          <div fxFlex="50%" fxLayout="column">
            <label for="amountAwardedTo" class="govuk-label">Amount to</label>
            <mat-form-field appearance="outline" class="govuk-!-width-full">
              <input
                type="text"
                [mask]="'separator.2'"
                matInput
                name="amountAwardedTo"
                [formControlName]="'amountAwardedTo'"
                placeholder="Enter amount to"
                id="amountAwardedTo"
                data-test="amountAwardedTo"
              />
              <mat-icon matSuffix>£</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="govuk-form-group govuk-!-width-full  govuk-!-margin-bottom-3">
        <label class="govuk-label" for="recipientOrganizationName">
          Recipient
        </label>
        <mat-form-field
          appearance="outline"
          (click)="focus(recipientOrganizationNameInput)"
          class="govuk-!-width-full govuk-form-group has-arrow"
        >
          <mat-chip-list
            #recipientOrganizationNameList
            aria-label="Select Recipient Organization Name"
            id="recipient-organization-name-chips-list"
          >
            <mat-chip
              *ngFor="let item of currentRecipientOrganizationNameData"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="
                remove(item.id, 'currentRecipientOrganizationNameData', '')
              "
            >
              {{ item.name }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              id="recipientOrganizationName"
              data-test="recipientOrganizationName"
              #recipientOrganizationNameInput
              [formControlName]="'recipientOrganizationName'"
              name="recipientOrganizationName"
              [matAutocomplete]="recipientOrganizationNameAutocomplete"
              [matChipInputFor]="recipientOrganizationNameList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              placeholder="Start typing name"
            />
          </mat-chip-list>
          <mat-autocomplete
            #recipientOrganizationNameAutocomplete="matAutocomplete"
            (optionSelected)="
              selected(
                $event,
                'currentRecipientOrganizationNameData',
                '',
                'recipientOrganizationName'
              )
            "
          >
            <mat-option *ngIf="isLoading">{{ loading }}</mat-option>
            <mat-option *ngIf="noResults">{{ noResult }}</mat-option>
            <mat-option
              *ngFor="let item of filteredRecipientOrganizationNameData"
              [value]="item"
            >
              {{ item.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="govuk-hint">
          {{ hint }}
        </div>
      </div>
    </div>
    <div
      class="govuk-!-margin-top-3"
      fxLayout="row"
      fxLayoutAlign="space-around center"
      fxLayout.xs="column"
    >
      <button
        type="submit"
        class="govuk-button"
        data-module="govuk-button"
        [class.loading]="filtersProcessing"
        [disabled]="filtersProcessing"
      >
        Apply filters
      </button>
      <button
        [class.loading]="resetProcessing"
        [disabled]="resetProcessing"
        data-test="reset-button"
        (click)="resetForm()"
        type="button"
        class="govuk-button govuk-button--secondary"
        data-module="govuk-button"
      >
        Reset
      </button>
    </div>
  </form>
</div>
