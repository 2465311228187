<div fxLayout="column" fxFlexFill>
  <app-header [ngClass]="{'govuk-!-display-none': isMobile && isSidebarVisible}">
    <a
      tabindex="0"
      href="#main"
      (click)="onMainLinkClick($event)"
      class="gem-c-skip-link govuk-skip-link govuk-!-display-none-print"
      >Skip to main content</a
    >
  </app-header>
  <main fxFlex>
    <mat-drawer-container
      class="govuk-sidebar__container"
      [ngClass]="{ 'reset-height': !showFilters }"
      [ngClass.xs]="'mobile'"
    >
      <mat-drawer
        *ngIf="showFilters"
        class="govuk-sidebar__filters"
        mode="side"
        [(opened)]="isSidebarVisible"
        (opened)="onOpen()"
        (closed)="onClose()"
      >
        <div
          (click)="closeFilters()"
          class="govuk-sidebar__collapse"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <button
            #btnRef="matButton"
            mat-icon-button           
            aria-label="Close filter"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>
          <p aria-hidden="true" class="govuk-body-s">Close filter</p>
        </div>

        <app-filters></app-filters>
      </mat-drawer>
      <mat-drawer-content
        tabindex="0"
        class="govuk-sidebar__content"
        [ngClass]="{ 'no-margin': !showFilters }"
      >
        <div id="main" [ngClass]="{'govuk-!-display-none': isMobile && isSidebarVisible}">
          <router-outlet (activate)="onActivate()"></router-outlet>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </main>
  <app-footer
  [ngClass]="{'govuk-!-display-none': isMobile && isSidebarVisible}"
  ></app-footer>
</div>
