// ────────────────────────────────────────────────────────────────────────────────
// Api routes
export const REGION: string = 'region';
export const LOCAL_AUTHORITY: string = 'local-authority';
export const UK_CONSTITUENCY: string = 'uk-constituency';
export const WARD: string = 'ward';
export const GOOD_CAUSE_AREA: string = 'good-cause-area';
export const FUNDING_ORG: string = 'funding-org';
export const RECIPIENT_ORG: string = 'recipient-org';
// ────────────────────────────────────────────────────────────────────────────────
// Static data
export const LOADING: string = 'Loading...';
export const NO_RESULTS: string = 'No results';
export const FILTER_HINT: string = 'Search will start from 3 characters';
// ────────────────────────────────────────────────────────────────────────────────
// Other statis variables
export const ENTER_CHARS_TO_START_SEARCH: number = 2;
export const FILTER_SEARCH_SEPARATOR: string = ';';
