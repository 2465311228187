import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { FooterModule } from './layout/footer/footer.module';
import { HeaderModule } from './layout/header/header.module';
import { FiltersModule } from './layout/filters/filters.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from './shared/shared.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
// ────────────────────────────────────────────────────────────────────────────────
// Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// ────────────────────────────────────────────────────────────────────────────────
// Datepicker locale
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

// ────────────────────────────────────────────────────────────────────────────────
// Application locale
import { LocationStrategy, registerLocaleData } from '@angular/common';
import localeGb from '@angular/common/locales/en-GB';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';

import { environment } from 'src/environments/environment';

import { CookieService } from 'ngx-cookie-service';
import { WindowRef } from './shared/helpers/window.ref';
import { CustomDateAdapter } from './layout/filters/custom-date.adapter';

registerLocaleData(localeGb, 'gb');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  position: 'top',
  theme: 'block',
  revokable: false,
  animateRevokable: false,
  autoOpen: true,
  palette: {
    popup: {
      background: '#0b0c0c',
      text: '#ffffff',
      link: '#ffffff',
    },
  },
  type: 'opt-out',
  layout: 'dcms-cookie',
  layouts: {
    'dcms-cookie': '{{messagelink}}{{compliance}}',
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="read cookie policy" tabindex="0" class="cc-link" href="{{detailsUrl}}" target="_self">{{policy}}</a>
    </span>
    `,
    deny: '<a href="{{policyUrl}}" aria-label="Set cookie preferences" role="button" tabindex="0" class="cc-btn cc-govuk-button">{{link}}</a>',
    allow:
      '<button aria-label="Accept all cookies" role="button" tabindex="0" class="cc-btn cc-govuk-button cc-allow">{{allow}}</button>',
  },
  content: {
    message: `Tell us whether you accept cookies.
      We use cookies to collect information about how you use GOV.UK. We use this information to make the website work as well as possible and improve government services.`,
    deny: '',
    link: 'Set cookie preferences',
    detailsUrl: 'cookie-details',
    policyUrl: 'cookie-policy',
    policy: 'Cookie policy',
    allow: 'Accept all cookies',
  },
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    FlexLayoutModule,
    FiltersModule,
    SharedModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    PerfectScrollbarModule,
    MatSnackBarModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    CookieService,
    WindowRef,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: localeGb, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
