import { Environment } from 'src/app/shared/interfaces/environment.interface';

export const environment: Environment = {
  production: false,
  hmr: false,
  cookieDomain: 'dev.lottery.dcms.gov.uk',
  uploadFileUrl: 'https://console.cloud.google.com/storage/browser/combined-distributor-data-d613ae26',

  getUploadUrl: 'dev.lottery.dcms.gov.uk/api/v1/upload/',
  firebase: {
    apiKey: 'AIzaSyAvyFxpPN9Zz50faqxqKFSk-qvQr_lMeJU',
    authDomain: 'lottery-grants-dev-297915.firebaseapp.com',
  }
};
