import { Injectable } from '@angular/core';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { StorageService } from 'src/app/shared/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth: any;

  /**
   * @param  {StorageService} privatestorageService
   */
  constructor(private storageService: StorageService) {}

  initAuth(firebase) {
    this.auth = getAuth(firebase);
  }

  signIn(email: string, password: string, onSuccess?: any, onFail?: any): any {
    return signInWithEmailAndPassword(this.auth, email, password)
      .then(data => {
        const user: any = data?.user;
        if (user) {
          this.storageService.saveAccessTokenData(user.accessToken);
          this.storageService.saveExpirationTimeData(user.stsTokenManager.expirationTime);

          if (onSuccess) {
            onSuccess(user);
          }
        }
        return data;
      })
      .catch(err => {
        if (onFail) {
          onFail(err);
        }

      });
  }

  isLoggedIn(): boolean {
    const accessToken: string = this.storageService.getAccessTokenData();
    const expirationTime: number = +this.storageService.getExpirationTimeData();

    if (expirationTime < Date.now()) {
      this.storageService.clearAccessTokenData();
      this.storageService.clearExpirationTimeData();
      return false;
    }

    return !!accessToken;
  }
}
