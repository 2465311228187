import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AuthService } from './services/auth/auth.service';
import { MessageService } from './services/message/message.service';
import { FiltersService } from './services/filters/filters.service';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { GrantsService } from './services/grants/grants.service';
import { HttpErrorInterceptor } from './interceptors/error.interceptor';
import { StorageService } from './services/storage/storage.service';
import { ChartsService } from './services/charts/charts.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    AuthService,
    MessageService,
    FiltersService,
    HttpClient,
    GrantsService,
    StorageService,
    ChartsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    CurrencyPipe,
  ],
})
export class SharedModule {}
