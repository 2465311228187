import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { initAll } from 'govuk-frontend';
import { DOCUMENT } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { MessageService } from './shared/services/message/message.service';
import { takeUntil, filter, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  NgcCookieConsentService,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';

import { CookieService } from 'ngx-cookie-service';
import {
  COOKIE_ALLOW,
  COOKIE_CONSISTENT_NAME,
  COOKIE_DENY,
  GOOGLE_ANALYTICS_ID,
} from './shared/constants/general.constants';
import { WindowRef } from './shared/helpers/window.ref';
import { Helpers } from './shared/helpers/helpers';
import { environment } from 'src/environments/environment';
import { MatButton } from '@angular/material/button';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public isSidebarVisible: boolean;
  public isMobile = false;
  public showFilters: boolean = false;

  private shouldFocusBtn = false;
  private _unsubscribeAll: Subject<any>;
  private oldWidth: number;
  @ViewChild('btnRef') buttonRef: MatButton;
  private statusChangeSubscription: Subscription;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.oldWidth === window.innerWidth) {
      return;
    }
    this.oldWidth = window.innerWidth;
    this.isMobile = window.innerWidth <= 600;
    this.isSidebarVisible = !this.isMobile;
    this.renderer.removeClass(
      this.winRef.nativeWindow.document.body,
      'filter-open'
    );
  }
  /**
   * @param  {} @Inject(DOCUMENT
   * @param  {any} privatedocument
   * @param  {Router} privaterouter
   * @param  {ActivatedRoute} privateroute
   * @param  {MessageService} privatemessageService
   * @param  {NgcCookieConsentService} privateccService
   * @param  {CookieService} privatecookieService
   * @param  {WindowRef} privatewinRef
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private ccService: NgcCookieConsentService,
    private cookieService: CookieService,
    private winRef: WindowRef,
    private renderer: Renderer2,
    private titleService: Title
  ) {
    this._unsubscribeAll = new Subject();
    this.isMobile = window.innerWidth <= 600;
    this.oldWidth = window.innerWidth;

    this.initGoogleAnalytics();

    this.router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          return this.route;
        }),
        map((route) => route.firstChild),
        switchMap((routeData) => routeData.data)
      )
      .subscribe((res: { showFilters: boolean; title: string }) => {
        this.showFilters = !!res?.showFilters;
        this.isSidebarVisible = !!res?.showFilters && !this.isMobile;
        if (res?.title) {
          this.titleService.setTitle(res.title + ' – DCMS');
        }
      });

    this.messageService
      .getMessage()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ text, data }: { text: string; data: any }) => {
        if (text === 'cookieSettings') {
          this.initGoogleAnalytics(data);
        }
        if (text === 'openFilter') {
          this.openFilters();
          this.shouldFocusBtn = true;
        }
        if (text === 'closeFilter') {
          this.closeFilters();
        }
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        this.initGoogleAnalytics();
        this.messageService.sendMessage('cookieStatus', event.status);
      }
    );
  }

  public ngOnInit(): void {
    this.document.documentElement.classList.add('js-enabled');
  }

  public ngAfterViewInit(): void {
    initAll();
  }

  public ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this.statusChangeSubscription.unsubscribe();
  }

  public openFilters() {
    this.isSidebarVisible = true;
    if (this.isMobile) {
      this.renderer.addClass(
        this.winRef.nativeWindow.document.body,
        'filter-open'
      );
    }
  }

  public closeFilters() {
    this.isSidebarVisible = false;
    this.renderer.removeClass(
      this.winRef.nativeWindow.document.body,
      'filter-open'
    );
  }

  public onOpen() {
    this.messageService.sendMessage('filtersOpened');
    if (this.shouldFocusBtn) {
      this.buttonRef.focus();
    }
    this.shouldFocusBtn = false;
  }

  public onClose() {
    this.messageService.sendMessage('filtersClosed');
  }

  public onActivate() {
    let scrollToTop = this.winRef.nativeWindow.setInterval(() => {
      let pos = this.winRef.nativeWindow.pageYOffset;
      if (pos > 0) {
        this.winRef.nativeWindow.scrollTo(0, pos - 50);
      } else {
        this.winRef.nativeWindow.clearInterval(scrollToTop);
      }
    }, 10);
  }

  public onMainLinkClick(event: Event): void {
    this.winRef.nativeWindow.location.hash = '';
    event.preventDefault();
    this.winRef.nativeWindow.location.hash = '#main';
  }

  private initGoogleAnalytics(
    value: string = this.cookieService.get(COOKIE_CONSISTENT_NAME)
  ) {
    switch (value) {
      case COOKIE_DENY:
        this.cookieService.set(COOKIE_CONSISTENT_NAME, value, {
          domain: environment.cookieDomain,
        });
        this.removeGaCookies();
        break;

      case COOKIE_ALLOW:
        Helpers.initGtag({ targetId: `G-${GOOGLE_ANALYTICS_ID}` });
        this.cookieService.set(COOKIE_CONSISTENT_NAME, value, {
          domain: environment.cookieDomain,
        });
        break;

      default:
        this.removeGaCookies();
        break;
    }
  }

  private removeGaCookies() {
    setTimeout(() => {
      this.winRef.nativeWindow[`ga-disable-G-${GOOGLE_ANALYTICS_ID}`] = true;
      this.cookieService.deleteAll('/', '.dcms.gov.uk');
      this.cookieService.delete(`_ga_${GOOGLE_ANALYTICS_ID}`);
      this.cookieService.delete('_ga');
    }, 0);
  }
}
