import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import {
  ERROR_MESSAGE_HORIZONTAL_POSITION,
  ERROR_MESSAGE_TIME,
  ERROR_MESSAGE_VERTICAL_POSITION,
} from '../constants/general.constants';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private _snackBar: MatSnackBar, private router: Router) {}
  /**
   * @param  {HttpRequest<any>} request?
   * @param  {HttpHandler} next?
   * @returns Observable
   */
  intercept(
    request?: HttpRequest<any>,
    next?: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((error) => this.errorHandler(error)));
  }
  /**
   * @param  {any} error
   * @returns Observable
   */
  private errorHandler(error: any): Observable<HttpEvent<any>> {
    if (error.error instanceof ErrorEvent) {
      this.renderErrors(error, 'client');
    } else {
      this.renderErrors(error, 'server');
    }

    return throwError(error);
  }
  /**
   * @param  {} error
   * @param  {'server'|'client'} mode
   */
  private renderErrors(error, mode: 'server' | 'client') {
    const errorMessages: string[] = this.parseErrorMessages(error);

    if (Array.isArray(errorMessages) && errorMessages.length > 0) {
      for (let index = 0; index < errorMessages.length; index++) {
        const element = errorMessages[index];
        if (error.status === 500) {
          this.router.navigate(['500']);
        }
        this.showError(`${error.status}: ${element}`);
      }
    }
  }
  /**
   * @param  {} error
   */
  private parseErrorMessages(error) {
    let errorMessages: string[] = [];
    if (error && typeof error.error === 'object') {
      for (const key in error.error) {
        if (Object.prototype.hasOwnProperty.call(error.error, key)) {
          const element: string[] = error.error[key];
          if (Array.isArray(element) && element.length === 1) {
            errorMessages.push(element[0]);
          }
        }
      }
    }

    if (error && typeof error.error === 'string' && error.error !== '') {
      errorMessages.push(error.statusText);
    }

    return errorMessages;
  }

  private showError(message: string, action: string = '') {
    this._snackBar.open(message, action, {
      duration: ERROR_MESSAGE_TIME,
      horizontalPosition: ERROR_MESSAGE_HORIZONTAL_POSITION,
      verticalPosition: ERROR_MESSAGE_VERTICAL_POSITION,
    });
  }
}
